<template>
<div>   
	  <XModal v-if="grid && grid.detail" :name="name" :resizable="true" :width="width+'%'" :height="height+'%'" :draggable="true" >
	  
	  <div class="RDOpenProgram">
			 		<div class="RDProgram">
			 		    <span class="RDProgramTime">
			 				{{ printTime2(grid.starttime)}} 
			 				<span v-show='grid.diff' class="RDProgramTime2">
				 				({{ printTime2(grid.starttime+grid.diff)}})
				 			</span>
			 			</span>
			 			
			 			 <span class="RDBreakDurProgress">
					    		<progress-bar 
					              :options="(100*sum( spots)/grid.adDurationInSec)<=100 ? PBOptions: PBOptionsRed"
					              :value="Math.round(100*sum( spots)/grid.adDurationInSec)"
					              />
					    </span>
			 			
				 		<div class="RDProgramTitle">
				 			{{ grid.detail.name }}
				 		</div>
				 		
				 		<span class="RDBreakDur">
					 			{{ printTimeMMSS( sum( spots)) }} / 
					 			{{ printTimeMMSS( grid.adDurationInSec) }} 
					 		</span>
					   <div class='btn' @click='close' style='float: right; display: inline-flex;'>
					   <mdicon size='15pt' name='window-close'/>
					   </div>
			 		</div>
		</div>	 	
		
	 	<div style='position: relative;padding-bottom: 5pt;' v-for="(spc,idx1) in grid.adspace" :key="'AS'+idx1">
	 	   <div style='position: relative;' v-for="(brk,idx2) in spc.breakTypes" :key="'BT'+idx1+'.'+idx2">
		 				<div :class='getBreakClass(spotsPerBreak[ keyBRK(spc,brk)])'>
		 				 
			 				<span class="RDBreakTime">
				 				{{printTime2(spc.estStarttime)}}
				 				<span v-show='grid.diff' class="RDBreakTime2">
					 				({{ printTime2(spc.estStarttime+grid.diff)}})
					 			</span>
				 			</span>
				 			
					 		<span class="RDBreakTitle">

					 			{{ getBreakCode( spc, brk) }}
					 		</span>
				 			
					 		<span class="RDBreakDur">
					 			{{ printTimeMMSS( sum( spotsPerBreak[ keyBRK(spc,brk)]) )}}
					 		</span>
					 		
							
		 				</div>
						<SimpleSpotList v-if="spotsPerBreak[ keyBRK(spc,brk)]"
						    :ref="getBreakCode( spc, brk)"
							
							:grid=grid
							:name="getBreakCode( spc, brk)"
							:identifier="getBreakCode( spc, brk)"
							:update="1"
							:fontSize="11"
							:dayId="Day1"
							:user=user
							:selectedIN=columnsRD 
							:spots="spotsPerBreak[ keyBRK(spc,brk)]?spotsPerBreak[ keyBRK(spc,brk)]:[]">
					    </SimpleSpotList>
		 		</div>
		 </div>


	  </XModal>
	  
</div>
</template>


<script>
import {HTTP, opsAPI, fwAPI, invAPI, setReload, showError} from '@/variables.js';
import { printTime2, printTimeMMSS } from '@/basicTimeFN.js';
import InputDateTime from '@/components/inputElements/InputDateTime';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputText from '@/components/inputElements/InputText';
import GSelectSimple from '@/components/GSelectSimple';
import Checked from '@/components/Ops/Checked';
import GConfirm from '@/components/GConfirm';
import ClickOutside from 'vue-click-outside'
import vSelect from 'vue-select'
import SimpleSpotList from '@/components/SimpleSpotList';

import JQuery from "jquery";
let $ = JQuery;

export default {
  name: 'GRID_BREAK_VIEWER',
  components : {
   SimpleSpotList
  },
  props: {
    name: {
      type: String,
      default: 'PDFWidget'
    },
    
    height: {
      type: Number,
      default: 94
    }
  },
  data () {
    return {
		title: "",
		grid: {},
		spots: [],
		spotsPerBreak: [],
		width: 70,
		columnsRD: "Pos,Placement,Agency,Client,ProductFamily,Product,Dur,Copy,HouseNumber,GrossUSD,Status",
		printTime2,
		printTimeMMSS,
		PBOptions: {
		  text: {
			color: '#000',
			shadowEnable: false,
		    fontSize: 11,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#6d6',
		    backgroundColor: '#dedeff'
		  },
		  layout: {
		    height: 14,
		    width: 75,
		    verticalTextAlign: 71,
		    horizontalTextAlign: 43,
		    zeroOffset: 0,
		    strokeWidth: 0,
		    progressPadding: 0,
		    type: 'line'
		  }
		 },
		 PBOptionsRed: {
		  text: {
			color: '#fff',
			shadowEnable: false,
		    fontSize: 11,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#f66',
		    backgroundColor: '#ffe8e8'
		  },
		  layout: {
		    height: 14,
		    width: 75,
		    verticalTextAlign: 71,
		    horizontalTextAlign: 43,
		    zeroOffset: 0,
		    strokeWidth: 0,
		    progressPadding: 0,
		    type: 'line'
		  }
		 }
    }
  },
  directives:{
    ClickOutside,
  },
  
  methods: {
     close() {  this.$modal.hide(this.name) },
     getWidth() { return this.width+"%";},
     getBreakClass(nspots)
     {
     	if ( nspots )
     	{
     		return "RDBreak";
     	}
     	return "RDBreakOff";
     },
	 open( title, grid, spots) {
	    this.title = title;
	    this.grid = grid;
	    this.spots = spots;
	 	this.spotsPerBreak = new Array();
	 	for ( let s in spots )
	 	{
	 		let spot = spots[s];
	 		let arr = this.spotsPerBreak[this.key(spot)];
	 		if ( !arr )
	 		{
	 			arr = new Array();
	 			this.spotsPerBreak[this.key(spot)] = arr;
	 		} 
	 		arr.push( spot);
	 	}
	    this.$nextTick(function () {
	    	this.$modal.show(this.name);
	    });
	 },
	 key(spot) { return spot.breakNo+":"+spot.breakTypeId},
	 keyBRK(spc,brk) { return spc.no+":"+brk.id},
	 sum( spots)
	 {
		let dur = 0;
		for ( let i in spots )
		{
			dur += spots[i].duration;
		}
		return dur;
	 },
	 getBreakCode(adSpace, brk)
      {
        let code = "";
        if ( adSpace.section == 0 )
        {
        	code = "";
        }
        else if ( adSpace.section == 2 )
        {
        	code =  "";
        }
        else
        {
        	code =  "C-"+adSpace.no;
        }
        code += " "+brk.shortname;
       
      	return code;
      },
  },
  watch: {

  },
  created() {

  }
}
</script>
<style scoped >

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.btn {
	cursor: pointer;
}
.btn:hover {
	background-color: #ddd;
}
.RDOpenProgram {
    margin-top: 2pt;
    margin-bottom: 8pt;
    padding: 2px;
	border-top: 2px outset #ddd;
	border-left: 1px outset #888;
	border-bottom: 1px outset #ccc;
}
.RDProgramTitle {
    display: inline-flex;
    padding-left: 5pt;
	font-size: 14px;
	font-weight: bold;
	background-color: #fff;
}
.RDProgramTime {
    display: inline-flex;
	font-size: 14px;
	width: 120px;
	color: #444;
	font-weight: bold;
	background-color: #fff;
}
.RDProgramTime2 {
    display: inline-flex;
	font-size: 14px;
	padding-left: 10pt;
	color: #888;
	font-weight: bold;
	background-color: #fff;
}
.RDProgram {
    width: 100%;
    
	font-size: 14px;
	font-weight: bold;
	background-color: #fff;
	position: relative;
}
.RDBreakDurProgress {
	position: relative;
    display: inline-flex;
    //border: 1pt solid grey;
    align-items: center;
    justify-content: center;
    vertical-align: center !important;
    padding-left: 10pt;
    padding-right: 60pt;
 	padding-bottom: 0pt;
	font-weight: bold;
	background-color: #fff;
}
.RDBreakDur {
    display: inline-flex;
    //float: right;
    padding-left: 5pt;

	font-weight: bold;
	background-color: #fff;
}
.RDBreakTime {
    display: inline-flex;
	width: 120px;
	color: #444;
	font-weight: bold;
	background-color: #fff;
}
.RDBreakTime2 {
    display: inline-flex;
	padding-left: 10pt;
	color: #888;
	font-weight: bold;
	background-color: #fff;
}
.RDBreakTitle {
	display: inline-flex;
	width: 80pt;
}
.RDBreak {
	font-size: 12px;
	font-weight: bold;
	background-color: #fff;
	border-top: 2px outset #ddd;
	text-align: left;
	padding-left: 6pt;
}
.RDBreakOff {
	font-size: 12px;
	font-weight: bold;
	color: #aaa;
	background-color: #fff;
	border-top: 2px outset #ddd;
	//text-shadow: 1px 1px 0px #ccc;
	text-align: left;
	padding-left: 6pt;
}
</style>
